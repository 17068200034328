import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Pagination } from '@mantine/core'
import { memo, useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { SymbolListChangeFill } from '~/modules/symbolPlatform/changFill/SymbolPlatformChangeFill'

import { StockSelectionBoard } from './component/StockSelectionBoard'
import { revive_store } from './revive_store'

// 四向選股
// 多方
// 17/88日均線多排 股價在所有均線之上
// 17/88日均線多排 股價在17日均線之下
// 近三季度營收或毛利或淨利率QoQ%或YoY%增長
// 近三周800張以上大戶比率增加
// 日線MACD柱體顏色由藍轉紅

// 空方
// 17/88日均線空排 股價在所有均線之下
// 17/88日均線空排 股價在17日均線之上
// 近三季度營收或毛利或淨利率QoQ%或YoY%下降
// 近三周800張以上大戶比率減少
// 日線MACD柱體顏色由紅轉藍

export const Revive_SidePane2 = memo<ReactProps>(function Revive_SidePane2() {
  const state = useSnapshot(revive_store)

  //Pagination--
  const [activePage, setPage] = useState(1)

  const uniqueData = Array.from(new Set(state.stockSymbol))
  const itemsPerPage = 10
  const totalItems = uniqueData.length

  /** 更換條件時 將起始值設為1 */
  useEffect(() => {
    setPage(1)
  }, [state.stockSelectionCondition])

  /** 起始 */
  const startIndex = (activePage - 1) * itemsPerPage
  /** 終止 */
  const endIndex = startIndex + itemsPerPage
  /** 總頁數 */
  const totalPage = Math.ceil(totalItems / itemsPerPage)
  /** 對應頁數返回的商品列 */
  const slicedItems = uniqueData.slice(startIndex, endIndex)

  //Pagination--

  return (
    <styleds.container>
      <styleds.searchTitle>選股</styleds.searchTitle>
      <StockSelectionBoard />
      <styleds.resultContent>搜尋結果: {uniqueData.length}檔標的</styleds.resultContent>
      <styleds.symbolListContent>
        {state.stockSelectionCondition.length >= 2 ? (
          <SymbolListChangeFill
            symbol={slicedItems}
            watchListGroup='stocknerve_group_1'
            sort={false}
          />
        ) : (
          <div>請選擇兩種以上條件</div>
        )}
      </styleds.symbolListContent>
      <styleds.paginationContent>
        <Pagination
          total={totalPage === 0 ? 1 : totalPage}
          siblings={0}
          defaultValue={activePage}
          onChange={val => setPage(val)}
        />
      </styleds.paginationContent>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    background-color: #282a31;
    padding: 8px;
    gap: 4px;
  `,
  watchlistButton: styled.div`
    ${fill_horizontal_all_center};
    height: 30px;
  `,
  searchTitle: styled.div`
    ${fill_horizontal_all_center};
    height: 30px;
    background-color: #494b57;
    border-radius: 4px;
  `,
  resultContent: styled.div`
    ${fill_horizontal_all_center};
    background-color: #25262a;
    height: 26px;
    font-size: 12px;
    border-radius: 4px;
  `,
  searchContent: styled.div`
    ${fill_horizontal_all_center};
    height: 32px;
    background-color: #252525;
  `,
  symbolListContent: styled.div`
    ${fill_vertical_all_center};
    width: 100%;
    height: calc(100% - 320px);
  `,
  paginationContent: styled.div`
    ${fill_vertical_all_center};
    height: 30px;
  `,
}
