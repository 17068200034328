import { css } from '@emotion/react'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss'
import SymbolPlatformBase from '../../modules/symbolPlatform/base/SymbolPlatformBase'

const symbols = [
  'XAUUSD',
  'HONGKONG50',
  'JAPAN225',
  'GERMANY40',
  'UK100',
  'US30',
  'US500',
  'USTECH100',
  'CL-1',
  'XBRUSD',
  'CN-1',
]

const watchListGroup = 'tc1688_group_1'

export const Tc1688_SidePane1 = memo<ReactProps>(function Tc1688_SidePane1() {
  return (
    <div
      css={css`
        ${fill_vertical_all_center};
        padding: 4px;
        height: calc(100% - 48px);
      `}
    >
      <SymbolPlatformBase.Display
        symbol={symbols}
        watchListGroup={watchListGroup}
        searchListType='customized'
      />
    </div>
  )
})
