import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { daddy960_tc1688_atrStopV3 } from '~/trades/indicators/daddy960_tc1688/daddy960_tc1688_atrStopV3'
import { daddy960_tc1688_heikinAshi } from '~/trades/indicators/daddy960_tc1688/daddy960_tc1688_heikinAshi'
import { daddy960_tc1688_qqTony } from '~/trades/indicators/daddy960_tc1688/daddy960_tc1688_qqTony'
import { daddy960_tc1688_turtle } from '~/trades/indicators/daddy960_tc1688/daddy960_tc1688_turtle'
import { valueProvider } from '~/trades/indicators/daddy960_tc1688/daddy960_tc1688_valueProvider'
import { store } from '../heineken_template/_private/store'
import { daddy960_tc1688_macdImpluseTv } from '~/trades/indicators/daddy960_tc1688/daddy960_tc1688_macdImpluseTv'
import { daddy960_tc1688_heikinAshi2 } from '~/trades/indicators/daddy960_tc1688/daddy960_tc168_heikinAshi2'
import dayAPI from '~/utils/dayAPI'

const DEFAULT_SYMBOL = 'XAUUSD'

const tc1688Indicators = [
  daddy960_tc1688_heikinAshi,
  daddy960_tc1688_macdImpluseTv,
  daddy960_tc1688_turtle,
  daddy960_tc1688_atrStopV3,
  daddy960_tc1688_qqTony,
  valueProvider,
]

const indicators1 = [daddy960_tc1688_macdImpluseTv, daddy960_tc1688_atrStopV3, valueProvider]
const indicators2 = [
  daddy960_tc1688_macdImpluseTv,
  daddy960_tc1688_heikinAshi,
  daddy960_tc1688_heikinAshi2,
  valueProvider,
]
const indicators3 = [daddy960_tc1688_macdImpluseTv, daddy960_tc1688_turtle, valueProvider]
const indicators4 = [daddy960_tc1688_macdImpluseTv, daddy960_tc1688_qqTony, valueProvider]

export const daddy960_tc1688_strategyGroup = {
  main: [
    {
      displayName: 'tc1688_1',
      indicators: indicators1,
      interval: 'D',
      panesRatio: 80,
      //calcFrom: dayAPI().subtract(14, 'day'),
    },
    {
      displayName: 'tc1688_2',
      indicators: indicators2,
      interval: 'D',
      panesRatio: 80,
    },
    {
      displayName: 'tc1688_3',
      indicators: indicators3,
      interval: 'D',
      panesRatio: 80,
    },
    {
      displayName: 'tc1688_4',
      indicators: indicators4,
      interval: 'D',
      panesRatio: 80,
    },
  ] as ChartTypes.StrategyConfig[],
}

export const daddy960_tc1688_initStrategies = () => {
  store.charting.indicatorsPreloaded = [...store.charting.indicatorsPreloaded, ...tc1688Indicators]

  store.charting.initStrategy({
    configs: [...daddy960_tc1688_strategyGroup.main],
  })

  store.charting.changeSymbol(DEFAULT_SYMBOL)
}
