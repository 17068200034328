import { css, Paper, Typography } from '@mui/material'
import { memo } from 'react'
import { ThemeToggle } from '~/components/theme/ThemeToggle'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { agentConfigs2 } from '~/configs/agentConfigs'
import { createDesktopCss } from '~/css/createDesktopCss'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
  flex,
} from '~/modules/AppLayout/FlexGridCss'
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch'
import { store } from '../heineken_template/_private/store'

export const Fubon_TopBar = memo<ReactProps>(function Fubon_TopBar() {
  const theme = useThemeStore(t => t.theme)

  return (
    <div css={fill_horizontal_all_center}>
      <div
        className={theme}
        css={css`
          ${fill_horizontal_cross_center};

          border-bottom: 2px solid #0bceff;
          padding: 0px 4px;

          &.dark {
            background-color: #2e343a;
          }
          &.light {
            background-color: #fafafa;
          }
        `}
      >
        <img
          src={theme === 'light' ? agentConfigs2['fubon@web'].favicon : '/fubon/favicon-dark.png'}
          css={css`
            width: 40px;
            height: 40px;
          `}
        />

        <Typography
          css={css`
            display: none;
            ${createDesktopCss(
              css`
                display: block;
                padding: 0px 4px;
                font-size: 18px;
                font-weight: 600;
              `,
            )}
          `}
        >
          {agentConfigs2['fubon@web'].title}
        </Typography>

        <div
          css={css`
            ${flex.h.crossCenter};
            margin-left: auto;
          `}
        >
          <ChartingServerSwitch charting={store.charting} />
          <ThemeToggle
            onClick={event => {
              store.charting.setThemeMode(useThemeStore.getState().theme)
              store.charting.updateFromThemeMode()
            }}
          />
          <UserAvatarAsDialogButton />
        </div>
      </div>
    </div>
  )
})
