import { useSnapshot } from 'valtio'
import { optionAnalyzeStore } from './optionAnalyzeStore'
import { OptionData } from './useOptionQuote'

export const getOptionCheap = (
  data: OptionData[],
  atTheMoneyPrice: number,
  atTheMoneySum: number,
) => {
  /**
   * 楊叔特製選擇權指標邏輯
   * 價平雙賣 
   * 價外Put買 prevStrikePrice 
   * 價外Call買 nextStrikePrice 
   * 組成禿鷹選擇權價差單
   */

  /** 標準差-高 2023/02/27數值 */
  const stdvH = 0.0176
  /** 標準差-低 2023/02/27數值 */
  const stdvL = 0.0204

  /** Put履約價位置 */
  const ITMStrikePrice = atTheMoneyPrice * (1 - stdvL)
  /** Call履約價位置 */
  const OTMStrikePrice = atTheMoneyPrice * (1 + stdvH)

  /** 純履約價data陣列 */
  const strikePriceData = data.length ? data.map(s => Number(s.strikePrice)) : [0]

  /** 價外put位置 */
  const ATMPrice = strikePriceData.reduce((prev, curr) => {
    return Math.abs(curr - ITMStrikePrice) < Math.abs(prev - ITMStrikePrice) &&
      ITMStrikePrice >= curr
      ? curr
      : prev
  })

  /** 價外call位置 */
  const OTMPrice = strikePriceData.reduce((prev, curr) => {
    return Math.abs(curr - OTMStrikePrice) < Math.abs(prev - OTMStrikePrice) &&
      OTMStrikePrice >= curr
      ? curr
      : prev
  })

  const ITMSum = data.filter(s => Number(s.strikePrice) === Number(ATMPrice)).map(s => s.pxp)[0]

  const OTMSum = data.filter(s => Number(s.strikePrice) === Number(OTMPrice)).map(s => s.pxc)[0]

  /** 上下標準差履約價 */
  const a = ITMSum + OTMSum
  /** 價平和履約價 */
  const b = atTheMoneySum

  const value = ((3 * a) / (2 * b)) * 100

  return Math.floor(value * 100) / 100
}
