import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { store } from '~/pages/heineken_template/_private/store'
import { eurex_trend_line } from '~/trades/indicators/eurex/eurex_trend_line'
import { pionex_strategy01 } from '~/trades/indicators/pionex/pionex_strategy01'
import { pionex_strategy02 } from '~/trades/indicators/pionex/pionex_strategy02'
import { pionex_strategy03 } from '~/trades/indicators/pionex/pionex_strategy03'
import { pionex_strategy04 } from '~/trades/indicators/pionex/pionex_strategy04'
import { pionex_strategy05 } from '~/trades/indicators/pionex/pionex_strategy05'
import { pionex_strategy06 } from '~/trades/indicators/pionex/pionex_strategy06'

const trade01 = []
const trade02 = []

export const pionex_strategiesGroup = {
  /** 主要策略集 */
  main: [
    {
      displayName: '短區間突破',
      indicators: [pionex_strategy01],
      interval: '60',
      describe: '短區間突破',
      type: 'default',
    },
    {
      displayName: '順勢',
      indicators: [pionex_strategy02],
      interval: '30',
      describe: '順勢',
      type: 'default',
    },
    {
      displayName: '區間逆勢短單',
      indicators: [pionex_strategy03],
      interval: '15',
      describe: '乖離或行情短暫過大時',
      type: 'default',
    },
    {
      displayName: '待修正1',
      indicators: [pionex_strategy04],
      interval: '5',
      describe: '-',
      type: 'default',
    },
    {
      displayName: '待修正2',
      indicators: [pionex_strategy05],
      interval: '1',
      describe: '-',
      type: 'default',
    },
    {
      displayName: '爆量急殺抄底做多',
      indicators: [pionex_strategy06],
      interval: '15',
      describe: '下跌幅度過大且爆量時',
      type: 'long',
    },
  ] as ChartTypes.StrategyConfig[],
}

export const pionex_initStrategies = () => {
  store.charting.indicatorsPreloaded = [
    ...store.charting.indicatorsPreloaded,
    ...[eurex_trend_line],
    // ...trade01,
    // ...trade02,
  ]

  store.charting.initStrategy({
    configs: [...pionex_strategiesGroup.main],
  })
}
