import { SessionType } from '~/modules/SDK/Chart2/SessionType'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import dayAPI from '~/utils/dayAPI'

//DMI順勢
export const pionex_strategy05 = createIndicator({
  id: 'pionex-strategy05',
  displayName: 'pionex_strategy05',

  //簡易波動率
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const length = this._input(0)

      const close = this.ohlc.close
      const open = this.ohlc.open
      const high = this.ohlc.high
      const low = this.ohlc.low

      const dmi = this.dmi(length, 14)
      const di_long = dmi[0]
      const di_short = dmi[1]
      const adx = dmi[2]
      const di_long_array = this._context.new_var(di_long)
      const di_short_array = this._context.new_var(di_short)

      const long = this.PineJS.Std.sma(di_long_array, 2, this._context)
      const short = this.PineJS.Std.sma(di_short_array, 2, this._context)
      const entryType = context.new_var()

      const position = context.new_var()
      const entryPrice = context.new_var()

      position.get(1)

      let buy_icon = NaN
      let short_icon = NaN
      let buy_target_icon = NaN
      let short_target_icon = NaN
      let buy_exit_icon = NaN
      let short_exit_icon = NaN

      // ---------------------------------------- Condition ----------------------------------------
      // if (long > short && di_long > di_short && adx > di_short) {
      //   position.set(1)
      // } else if (long < short && di_long < di_short && adx > di_long) {
      //   position.set(-1)
      // } else {
      //   position.set(0)
      // }

      const longEntry =
        long > short &&
        di_long > di_short &&
        adx > di_short &&
        close < open &&
        entryType.get(0) !== 1
      const shortEntry =
        long < short &&
        di_long < di_short &&
        adx > di_long &&
        close > open &&
        entryType.get(0) !== -1

      // ---------------------------------------- Entry ----------------------------------------
      if (longEntry) position.set(1)
      if (shortEntry) position.set(-1)

      if (position.get(0) === 1 && position.get(1) !== 1) {
        entryPrice.set(close)
        entryType.set(1)
        buy_icon = 1
      }
      if (position.get(0) === -1 && position.get(1) !== -1) {
        entryPrice.set(close)
        entryType.set(-1)
        short_icon = 1
      }

      const sar_long = this.sarLongStopLoss('linear', 9, position, 10, 1.4, 0.6, 4)
      const sar_short = this.sarShortStopLoss('linear', 10, position, 10, 1.4, 0.8, 4)

      const longEnd = position.get(1) === 1 && position.get(0) === 1 && close < sar_long.get(0)
      const shortEnd = position.get(1) === -1 && position.get(0) === -1 && close > sar_short.get(0)

      // ---------------------------------------- Exit ----------------------------------------
      if (position.get(0) === 1 && position.get(1) === 1) {
        if (high > entryPrice.get(1) * 1.05) {
          buy_target_icon = 1
          position.set(0)
        }

        if (longEnd) {
          buy_exit_icon = 1
          position.set(0)
        }
      }

      if (position.get(0) === -1 && position.get(1) === -1) {
        if (low < entryPrice.get(1) * 0.95) {
          short_target_icon = 1
          position.set(0)
        }

        if (shortEnd) {
          short_exit_icon = 1
          position.set(0)
        }
      }

      //sar線--------------------------------------------------

      const sarLongLine = this._context.new_var()
      const sarShortLine = this._context.new_var()
      if (position.get(0) === 1) {
        sarLongLine.set(sar_long.get(0))
        sarShortLine.set(NaN)
      }

      if (position.get(0) === -1) {
        sarLongLine.set(NaN)
        sarShortLine.set(sar_short.get(0))
      }

      if (position.get(0) === 0) {
        sarLongLine.set(NaN)
        sarShortLine.set(NaN)
      }

      const colorer = () => {
        return position.get(0) === 1 ? 0 : position.get(0) === -1 ? 1 : 2
      }

      return [
        buy_icon,
        short_icon,
        buy_target_icon,
        short_target_icon,
        buy_exit_icon,
        short_exit_icon,
        sarLongLine.get(0),
        sarShortLine.get(0),
        colorer(),
      ]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',
    is_hidden_study: false,
    is_price_study: true,
    isCustomIndicator: true,
    plots: [
      {
        id: 'entryLongFlag',
        type: 'shapes',
      },

      {
        id: 'entryShortFlag',
        type: 'shapes',
      },
      {
        id: 'targetLongFlag',
        type: 'shapes',
      },
      {
        id: 'targetShortFlag',
        type: 'shapes',
      },
      {
        id: 'exitLongFlag',
        type: 'shapes',
      },
      {
        id: 'exitShortFlag',
        type: 'shapes',
      },
      {
        id: 'sarLong',
        type: 'line',
      },
      {
        id: 'sarShort',
        type: 'line',
      },
      {
        id: '部位',
        type: 'bar_colorer',
        palette: 'barPalette',
      },
    ],
    defaults: {
      styles: {
        entryLongFlag: { color: '#f04848', textColor: '#f04848', transparency: 10, visible: true },
        entryShortFlag: { color: '#00b070', textColor: '#00b070', transparency: 10, visible: true },
        targetLongFlag: { color: '#f04848', textColor: '#fafafa', transparency: 20, visible: true },
        targetShortFlag: {
          color: '#00b070',
          textColor: '#fafafa',
          transparency: 20,
          visible: true,
        },
        exitLongFlag: { color: '#f04848', textColor: '#fafafa', transparency: 20, visible: true },
        exitShortFlag: { color: '#00b070', textColor: '#fafafa', transparency: 20, visible: true },
        sarLong: { color: '#ff0000', textColor: '#ff0000', transparency: 70, visible: true },
        sarShort: { color: '#00aa00', textColor: '#00aa00', transparency: 70, visible: true },
      },
      inputs: { in_0: 14 },
    },
    styles: {
      entryLongFlag: {
        title: '買進',
        isHidden: false,
        location: 'BelowBar',
        plottype: 'shape_triangle_up',
        size: 'small',
        text: 'Buy',
      },
      entryShortFlag: {
        title: '賣出',
        isHidden: false,
        location: 'AboveBar',
        plottype: 'shape_triangle_down',
        size: 'small',
        text: 'Short',
      },
      targetLongFlag: {
        title: '買進停利',
        isHidden: false,
        location: 'AboveBar',
        plottype: 'shape_label_down',
        size: 'tiny',
        text: 'Target',
      },
      targetShortFlag: {
        title: '賣出停利',
        isHidden: false,
        location: 'BelowBar',
        plottype: 'shape_label_up',
        size: 'tiny',
        text: 'Target',
      },
      exitLongFlag: {
        title: '買進出場',
        isHidden: false,
        location: 'BelowBar',
        plottype: 'shape_label_up',
        size: 'tiny',
        text: 'Exit',
      },
      exitShortFlag: {
        title: '賣出出場',
        isHidden: false,
        location: 'AboveBar',
        plottype: 'shape_label_down',
        size: 'tiny',
        text: 'Exit',
      },
    },
    palettes: {
      barPalette: {
        colors: {
          0: {
            color: '#df484c',
            width: 1,
            style: 0,
          },
          1: {
            color: '#5cb642',
            width: 1,
            style: 0,
          },
          2: {
            color: '#b2b5be',
            width: 1,
            style: 0,
          },
        },
      },
    },
    inputs: [
      {
        id: 'in_0',
        name: '週期',
        defval: 14,
        type: 'integer',
        min: 1,
        max: 120,
      },
    ],
  },
})
