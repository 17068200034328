import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { proxy, useSnapshot } from 'valtio'
import {
  fill_horizontal_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { WatchListWithChart } from '~/modules/symbolQuote/simple'
import IntradayTrendChart from '~/modules/trendChart'
import { WatchSymbolToggleSelector } from '../heineken_template/_col/col_WatchSymbolToggleGroupSelector'
import { store } from '../heineken_template/_private/store'
import TitleSymbolQuote from '../../modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { SymbolInfoForm } from '../daddy960_goodway/component/SymbolInfoForm'

type WatchListGroupNameType =
  | 'stocknerve_group_1'
  | 'stocknerve_group_2'
  | 'stocknerve_group_3'
  | 'stocknerve_group_4'
  | 'stocknerve_group_5'

export const watchListConfig: { groupName: WatchListGroupNameType; displayText: string }[] = [
  { groupName: 'stocknerve_group_1', displayText: '群組1' },
  { groupName: 'stocknerve_group_2', displayText: '群組2' },
  { groupName: 'stocknerve_group_3', displayText: '群組3' },
  { groupName: 'stocknerve_group_4', displayText: '群組4' },
  { groupName: 'stocknerve_group_5', displayText: '群組5' },
]

export const groupStore = proxy({
  /** 一般自選股用的 */
  watchListGroup: 'stocknerve_group_1' as WatchListGroupNameType,
  /** 股期分數排序用 */
  watchListData: 'stocknerve_group_1' as WatchListGroupNameType,
  /** 股期分數預設資料 */
  defaultData: true as boolean,
})

//----------

export const Daddy960_stocknerve_SidePane2 = memo<ReactProps>(
  function Daddy960_stocknerve_SidePane2() {
    const charting = useSnapshot(store.charting)
    const symbolNumber = charting.symbol
    const groupState = useSnapshot(groupStore)

    return (
      <div
        css={css`
          ${fill_vertical_cross_center};
          user-select: none;
          padding: 4px;
        `}
      >
        <TitleSymbolQuote.Default symbol={symbolNumber} />
        <styleds.trendChartContent>
          <IntradayTrendChart
            symbol={symbolNumber}
            ticksSize={13}
            ticksHeight={20}
            priceTicksMargin={2.5}
            priceTicksSize={13}
          />
        </styleds.trendChartContent>
        <styleds.watchListToggleSelector>
          <WatchSymbolToggleSelector config={watchListConfig} />
        </styleds.watchListToggleSelector>
        <SymbolInfoForm symbol={symbolNumber} />
        <styleds.watchLsitContent>
          <styleds.watchListGroupSwitchContent>
            {watchListConfig.map((item, index) => {
              const isSelect = item.groupName === groupState.watchListGroup
              return (
                <styleds.watchListGroupButton
                  key={index}
                  selected={isSelect}
                  onClick={() => (groupStore.watchListGroup = item.groupName)}
                >
                  {item.displayText}
                </styleds.watchListGroupButton>
              )
            })}
          </styleds.watchListGroupSwitchContent>
          <WatchListWithChart
            groupName={groupState.watchListGroup}
            useChart={store.charting}
          />
        </styleds.watchLsitContent>
      </div>
    )
  },
)

const styleds = {
  trendChartContent: styled.div`
    width: 100%;
    height: 164px;
    padding: 4px;
  `,
  watchListToggleSelector: styled.div`
    width: 100%;
    height: 48px;
    & > * {
      font-size: 14px !important;
    }
  `,
  watchListGroupSwitchContent: styled.div`
    ${fill_horizontal_all_center};
    width: 100%;
    height: 48px;
    gap: 8px;
    font-size: 14px;
  `,
  watchListGroupButton: styled.div<{ selected: boolean }>`
    ${fill_horizontal_all_center};
    height: 30px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
    background-color: ${props => (props.selected ? '#4759cc' : '#252525')};
    &:hover {
      background-color: ${props => (props.selected ? '#4759cccc' : '#353535')};
    }
  `,
  watchLsitContent: styled.div`
    width: 100%;
    height: calc(100% - 380px);
  `,
}
