import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Avatar, Button, TextInput } from '@mantine/core'

import { useRouter } from 'next/router'
import { memo, useState } from 'react'
import { useAsyncFn } from 'react-use'
import { apirc } from '~/configs/apirc'
import { fontWeight500 } from '~/css/font'
import useMedia from '~/hooks/useMedia'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
  fill_vertical_main_center,
  flex,
  jc,
  pureGrid,
} from '~/modules/AppLayout/FlexGridCss'
import { useFirebaseMeState } from '~/modules/auth/containers/useFirebaseMeState'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import { fadeIn, fadeInTransformX } from '~/pages/eurex/_private/styleds'

const View = memo<ReactProps>(function View() {
  const auth = useFirebaseMeState.useContainer()
  const router = useRouter()

  return (
    <classes.container
      onClick={() => {
        auth.acts.loginWithGoogle().then(() => {
          router.reload()
        })
      }}
    >
      <classes.googleIcon src='google-icon.png' />
      Continue with Google
    </classes.container>
  )
})

export const UnregisteredView = memo<ReactProps>(function UnregisteredView() {
  const firebaseMe = useFirebaseMeState.useContainer()
  const meUserState = useMeStore(state => state.meUserState)

  /** 會員代碼 */
  const userNumber = useMeStore(state => state.meUserState?.code)
  /** 會員信箱 */
  const email = useMeStore(state => state.meUserState?.email)
  const photoUrl = useMeStore(state => state.meUserState?.photo)

  return (
    <div
      css={css`
        ${fill_vertical_all_center};
        gap: 16px;
      `}
    >
      <infoStyleds.toolTipContent>
        <p>權限尚未開通</p>
        <p>聯繫客服專員</p>
      </infoStyleds.toolTipContent>
      <infoStyleds.item>會員代碼 &nbsp;&nbsp;{userNumber}</infoStyleds.item>

      <Button
        css={css`
          width: 120px;
        `}
        variant='gradient'
        gradient={{ from: 'orange', to: 'red' }}
        onClick={() => firebaseMe.acts.logout()}
      >
        登出
      </Button>
    </div>
  )
})

export const Win988LoginView = memo<ReactProps>(function Win988LoginView(props) {
  const meFirebaseState = useMeStore(state => state.meFirebaseState)
  const { isPhone } = useMedia()

  const [ActivationCode, setActivationCode] = useState('')
  const [sendCodeState, sendCode] = useAsyncFn(
    async (code: string) => await apirc.me.api.postActivationCode.fetch(code),
    [],
  )

  return (
    <styleds.Container className={props.className}>
      <styleds.DemoContent
        css={css`
          display: ${isPhone ? 'none' : ''};
        `}
      >
        <styleds.Left>
          <styleds.LeftSubTitle>TRADING MASTER</styleds.LeftSubTitle>
          <styleds.LeftTitle>領航者</styleds.LeftTitle>
        </styleds.Left>
      </styleds.DemoContent>
      <styleds.Sidebar
        css={css`
          width: ${!isPhone ? '500px' : '100%'};
        `}
      >
        <styleds.LogoSloganContent>
          <styleds.LogoName src='win988/logo.png' />
          <styleds.Subtitle>
            <p>洞悉市場脈搏，引進財富航程</p>
          </styleds.Subtitle>
        </styleds.LogoSloganContent>

        <styleds.LogintContent>
          {!meFirebaseState && <styleds.LoginTitle>點擊下方按鈕開始使用</styleds.LoginTitle>}
          {!meFirebaseState && <View />}
          {meFirebaseState && <UnregisteredView />}
        </styleds.LogintContent>

        {meFirebaseState && (
          <styleds.PromoCodeContent>
            <styleds.PromoTitle>輸入啟用序號</styleds.PromoTitle>
            <styleds.PromoInput>
              <TextInput
                value={ActivationCode}
                placeholder='test-'
                variant='filled'
                onChange={event => {
                  setActivationCode(event.currentTarget.value)
                }}
              />
              <Button
                css={css`
                  width: 80px;
                `}
                gradient={{ from: 'indigo', to: 'cyan' }}
                variant='gradient'
                onClick={() => {
                  sendCode(ActivationCode)
                }}
              >
                {sendCodeState.loading ? '送出中' : '送出'}
              </Button>
            </styleds.PromoInput>

            {sendCodeState.value && (
              <styleds.PromoState>
                {sendCodeState.value?.activationCode}權限已啟用 請重整畫面
              </styleds.PromoState>
            )}
            {sendCodeState.error && <styleds.PromoState>查無序號 請重新輸入</styleds.PromoState>}
          </styleds.PromoCodeContent>
        )}

        <div
          css={css`
            grid-row: 8;
            ${fill_vertical_all_center};
          `}
        >
          聯繫客服
        </div>
      </styleds.Sidebar>
    </styleds.Container>
  )
})

const classes = {
  container: styled.div`
    ${fill_horizontal_cross_center};
    ${fontWeight500};
    padding: 0px 16px;
    width: 240px;
    height: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #aaaaaa;
    color: #7b7b7b;
    gap: 4px;
    cursor: pointer;
    &:hover {
      background-color: #f1f1f1;
    }
  `,
  googleIcon: styled.img`
    width: 32px;
  `,
}

const infoStyleds = {
  itemTitle: styled.p`
    ${fill_vertical_all_center};
    width: 120px;
    height: 32px;
    background-color: #5d7c9daa;
    border-radius: 5px;
    ${fontWeight500};
    margin: 0px;
  `,
  item: styled.p`
    ${fill_vertical_all_center};
    height: 32px;
    font-size: 16px;
    ${fontWeight500};
    margin: 4px;
  `,
  logoutButton: styled.div`
    ${fill_vertical_all_center};
    width: 260px;
    height: 32px;
    background-color: #ca4b4b;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 8px;
    &:hover {
      background-color: #da5b5b;
    }
  `,
  toolTipContent: styled.div`
    ${fill_vertical_all_center};
    height: 80px;
    width: 260px;
    background-color: #ca4b4b66;
    border-radius: 5px;

    > p {
      margin: 8px;
    }
  `,
  messageContent: styled.span`
    ${fill_horizontal_all_center};
    height: 30px;
  `,
}

const styleds = {
  Container: styled.div`
    ${fill_horizontal_all_center}
    position: fixed;
    z-index: 1300;
    font-family: Replica, Arial, serif;
    background-color: #191d25;
    color: #ffffff;
  `,
  Left: styled.div`
    width: 308px;
    animation: ${fadeInTransformX} 2s;
  `,
  LeftSubTitle: styled.div`
    color: #ffffff;
    font-size: 28px;
  `,
  LeftTitle: styled.div`
    ${fill_horizontal_all_center}
    color: #ffffff;
    height: 72px;
    line-height: 72px;
    background-color: #456786;
    -webkit-text-stroke: 0.3px #ffffff;
    padding: 4px 12px;
  `,
  Sidebar: styled.div`
    ${fill_vertical_all_center};
    ${pureGrid};
    grid-template-rows: repeat(8, 12.5%);
    grid-template-columns: 100%;
    background-image: linear-gradient(285deg, #141822, #363e4e);
    padding: 24px;
    font-family: Replica, Arial, serif;
    font-weight: 900;
    animation: ${fadeIn} 3s;
  `,
  DemoContent: styled.div`
    ${fill_vertical_main_center};
    background-color: #cccccc;
    width: calc(100% - 500px);
    background-image: linear-gradient(90deg, #252525, #252525aa, #252525aa),
      url('win988/login-view.png');
    background-position: left;
    background-size: cover;
    font-family: Replica, Arial, serif;
    font-weight: 900;
    font-size: 48px;
    padding: 32px;
    animation: ${fadeIn} 2s;
  `,
  LogoName: styled.img`
    width: 148px;
    grid-row: 1 / 3;
  `,
  LogoSloganContent: styled.div`
    grid-row: 3 / 4;
    ${fill_vertical_all_center};
  `,
  TitleWhite: styled.div`
    color: #ffffff;
    font-size: 36px;
    line-height: 1.5;
    -webkit-text-stroke: 0.3px #ffffff;
  `,
  Subtitle: styled.div`
    font-size: 18px;
    line-height: 8px;
  `,
  LoginTitle: styled.div`
    color: #e3e3e3;
    font-size: 24px;
  `,
  LogintContent: styled.div`
    ${fill_vertical_all_center};
    height: 240px;
    gap: 16px;
    grid-row: 4 / 7;
  `,
  Copyright: styled.div`
    ${fill_horizontal_all_center}
    grid-row: 8;
    align-items: end;
  `,
  PromoCodeContent: styled.div`
    grid-row: 7;
    ${fill_vertical_all_center};
    height: 108px;
  `,
  PromoTitle: styled.div`
    ${fill_vertical_all_center};
    height: 30px;
  `,
  PromoInput: styled.div`
    ${fill_horizontal_all_center};
    height: calc(100% - 60px);
  `,
  PromoState: styled.div`
    ${fill_horizontal_all_center};
    height: 30px;
    width: 260px;
    background-color: #ca4b4b33;
    color: #fafafa;
    border-radius: 5px;
    font-size: 14px;
  `,
}
