import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { store } from '../heineken_template/_private/store'
import { stocknerve_1222 } from '~/trades/indicators/daddy960_stocknerve/stocknerve_1222'
import { stocknerve_ma960SF } from '~/trades/indicators/daddy960_stocknerve/stocknerve_ma960'
import { stocknerve_2330 } from '~/trades/indicators/daddy960_stocknerve/stocknerve_2330'
import { stocknerve_as1 } from '~/trades/indicators/daddy960_stocknerve/stocknerve_as1'
import { VPVR } from '~/trades/indicators/defaults/VPVR'

const DEFAULT_SYMBOL = 'CDF-1'

const stockFutureIndicatorGroup = [
  VPVR,
  stocknerve_ma960SF,
  stocknerve_1222,
  stocknerve_as1,
  stocknerve_2330,
]

export const daddy960_stocknerve_strategyGroup = {
  main: [
    {
      displayName: 'Main strategy group',
      indicators: stockFutureIndicatorGroup,
      symbol: DEFAULT_SYMBOL,
      interval: '1',
      panesRatio: 66,
    },
  ] as ChartTypes.StrategyConfig[],
}

export const daddy960_stocknerve_initStrategies = () => {
  store.charting.indicatorsPreloaded = [
    ...store.charting.indicatorsPreloaded,
    ...stockFutureIndicatorGroup,
  ]

  store.charting.initStrategy({
    configs: [...daddy960_stocknerve_strategyGroup.main],
  })

  store.charting.changeSymbol(DEFAULT_SYMBOL)
}
