import styled from '@emotion/styled'
import { title } from 'process'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { apirc } from '~/configs/apirc'
import {
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import {
  useIntraday,
  useLatestTradeDateSize,
  usePercentRangeResource,
  useTurnoverRateResource,
} from '~/modules/screener/containers/useStockScreenerResource'
import { SignalWallId } from '~/modules/signal-wall/SignalWallId'
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple'
import dayAPI from '~/utils/dayAPI'
import { staticStore } from '../heineken_template/_private/staticStore'
import { store } from '../heineken_template/_private/store'

export const Wu5868_SidePane2 = memo<ReactProps>(function Wu5868_SidePane2(props) {
  const lastTradeDatePick = useLatestTradeDateSize({ size: 5 })
  const lastTradeDate = lastTradeDatePick?.[0]
  const beforeLastTradeDate = lastTradeDatePick?.[1]

  //盤中: 09:00 ~ 15:00 => lastTradeDate
  //收盤後: 15:00 ~ 05:00 => beforeLastTradeDate
  const intraday = useSnapshot(staticStore).tradedDate.intraday

  const fromDate = intraday
  const currentDate = dayAPI()

  const newHighSymbol = apirc.signalWall.data
    .useAsync({
      signal_id: SignalWallId.展哥突破5日高,
      from: fromDate,
      to: currentDate,
    })
    .data?.map(f => f.symbol)

  const lowBuySymbol = apirc.signalWall.data
    .useAsync({
      signal_id: SignalWallId.展哥拉回買進,
      from: fromDate,
      to: currentDate,
    })
    .data?.map(f => f.symbol)

  const newHighSymbol_ = newHighSymbol?.filter(
    (symbol, index) => newHighSymbol.indexOf(symbol) === index,
  )

  const lowBuySymbol_ = lowBuySymbol?.filter(
    (symbol, index) => lowBuySymbol.indexOf(symbol) === index,
  )

  //const symbol_c = turnoverData?.data?.map(s => s.symbol)

  //const allSymbol = symbol_a?.filter(item1 => symbol_b?.some(item2 => item2 === item1))

  return (
    <styleds.container>
      <div>選股日期 {fromDate.format('YYYY/MM/DD')}</div>
      <styleds.area>
        <styleds.title>創五日新高</styleds.title>
        <styleds.list>
          <SimpleQuoteListInteractWithChart
            data={newHighSymbol_ ?? []}
            chart={store.charting}
          />
        </styleds.list>
      </styleds.area>
      <styleds.area>
        <styleds.title>拉回買進</styleds.title>
        <styleds.list>
          <SimpleQuoteListInteractWithChart
            data={lowBuySymbol_ ?? []}
            chart={store.charting}
          />
        </styleds.list>
      </styleds.area>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    background-color: #fafafa;
    padding: 8px;
    gap: 2px;
    border-right: 1px solid #aaaaaa;
  `,
  area: styled.div`
    ${fill_vertical_all_center};
    height: 50%;
  `,
  title: styled.div`
    ${fill_vertical_all_center};
    height: 32px;
    background-color:#cecece;
    border-radius: 4px;
  `,
  list: styled.div`
    ${fill_vertical_all_center};
    height: calc(100% - 32px);
  `,
}
