import styled from '@emotion/styled'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import {
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { useExtremeVolumeResource } from '~/modules/screener/containers/useStockScreenerResource'
import { SymbolPlatformChangeFill } from '~/modules/symbolPlatform/changFill/SymbolPlatformChangeFill'
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList'
import { staticStore } from '../heineken_template/_private/staticStore'

export const Revive_SidePane1 = memo<ReactProps>(function Revive_SidePane1() {
  const date = useSnapshot(staticStore).tradedDate.day0

  const extremeVolumeData = useExtremeVolumeResource({
    date: date.format('YYYY-MM-DD'),
    days: 5,
    volume_gte: 5000,
    amount_gte: 50000000,
    threshold: 3,
  })

  return (
    <styleds.container>
      <styleds.card height={'calc(100% - 0px)'}>
        <SymbolPlatformChangeFill
          symbol={extremeVolumeData.data?.map(s => s.symbol) ?? []}
          watchListGroup='stocknerve_group_1'
        />
      </styleds.card>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    background-color: #282a31;
    padding: 8px;
    gap: 2px;
  `,
  card: styled.div<{ height: number | string }>`
    ${fill_vertical_all_center};
    height: ${props => props.height};
    background-color: #25262a;
    border-radius: 8px;
  `,
}
