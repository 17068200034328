import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button, Divider, TextInput } from '@mantine/core'
import { useRouter } from 'next/router'
import { memo, useState } from 'react'
import { useAsyncFn } from 'react-use'
import { apirc } from '~/configs/apirc'
import { fontWeight500 } from '~/css/font'
import useMedia from '~/hooks/useMedia'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
  fill_vertical_all_center,
  jc,
  pureGrid,
} from '~/modules/AppLayout/FlexGridCss'
import { useFirebaseMeState } from '~/modules/auth/containers/useFirebaseMeState'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import { fadeIn } from '~/pages/eurex/_private/styleds'

const View = memo<ReactProps>(function View() {
  const auth = useFirebaseMeState.useContainer()
  const router = useRouter()

  return (
    <classes.container
      onClick={() => {
        auth.acts.loginWithGoogle().then(() => {
          router.reload()
        })
      }}
    >
      <classes.googleIcon src='google-icon.png' />
      Continue with Google
    </classes.container>
  )
})

export const UnregisteredView = memo<ReactProps>(function UnregisteredView() {
  const firebaseMe = useFirebaseMeState.useContainer()
  /** 會員代碼 */
  const userNumber = useMeStore(state => state.meUserState?.code)

  return (
    <div
      css={css`
        ${fill_vertical_all_center};
        gap: 16px;
      `}
    >
      <infoStyleds.toolTipContent>
        <p>權限尚未開通</p>
        <p>聯繫客服專員</p>
      </infoStyleds.toolTipContent>
      <infoStyleds.item>會員代碼 &nbsp;&nbsp;{userNumber}</infoStyleds.item>

      <Button
        css={css`
          width: 120px;
        `}
        variant='gradient'
        gradient={{ from: 'orange', to: 'red' }}
        onClick={() => firebaseMe.acts.logout()}
      >
        登出
      </Button>
    </div>
  )
})

export const Pionex_LoginView = memo<ReactProps>(function Pionex_LoginView(props) {
  const meFirebaseState = useMeStore(state => state.meFirebaseState)
  const { isPhone } = useMedia()

  const [ActivationCode, setActivationCode] = useState('')
  const [sendCodeState, sendCode] = useAsyncFn(
    async (code: string) => await apirc.me.api.postActivationCode.fetch(code),
    [],
  )

  return (
    <styleds.Container className={props.className}>
      <div
        css={css`
          position: absolute;
          background-color: #ff9034;
          width: 100%;
          height: 60px;
          top: 0;
          z-index: 1400;
        `}
      ></div>
      <styleds.DemoContent
        css={css`
          display: ${isPhone ? 'none' : ''};
        `}
      >
        <styleds.LeftTitle>自動交易，低買高賣</styleds.LeftTitle>
        <styleds.LeftSubTitle>全球最大的數位貨幣交易機器人平臺 </styleds.LeftSubTitle>
        <styleds.LeftPionexBot />
      </styleds.DemoContent>
      <styleds.Sidebar
        css={css`
          width: ${!isPhone ? '500px' : '100%'};
        `}
      >
        <styleds.LogoSloganContent>
          <styleds.LogoFuturesaiName>
            <img src='/futuresai/icon.png' />
            <span>期天資訊</span>
          </styleds.LogoFuturesaiName>

          <styleds.LogoName src='pionex/pionex_icon.png' />
          <styleds.Subtitle>
            <p>全球最大的數位貨幣交易機器人平臺</p>
          </styleds.Subtitle>
        </styleds.LogoSloganContent>
        <styleds.LogintContent>
          {!meFirebaseState && <styleds.LoginTitle>點擊下方按鈕開始使用</styleds.LoginTitle>}
          {!meFirebaseState && <View />}
          {meFirebaseState && <UnregisteredView />}
        </styleds.LogintContent>
        {meFirebaseState && (
          <styleds.PromoCodeContent>
            <styleds.PromoTitle>輸入啟用序號</styleds.PromoTitle>
            <styleds.PromoInput>
              <TextInput
                value={ActivationCode}
                placeholder='test-'
                variant='filled'
                onChange={event => {
                  setActivationCode(event.currentTarget.value)
                }}
              />
              <Button
                css={css`
                  width: 80px;
                `}
                gradient={{ from: 'indigo', to: 'cyan' }}
                variant='gradient'
                onClick={() => {
                  sendCode(ActivationCode)
                }}
              >
                {sendCodeState.loading ? '送出中' : '送出'}
              </Button>
            </styleds.PromoInput>

            {sendCodeState.value && (
              <styleds.PromoState>
                {sendCodeState.value?.activationCode}權限已啟用 請重整畫面
              </styleds.PromoState>
            )}
            {sendCodeState.error && <styleds.PromoState>查無序號 請重新輸入</styleds.PromoState>}
          </styleds.PromoCodeContent>
        )}
        <div
          css={css`
            grid-row: 8;
            ${fill_vertical_all_center};
            ${jc.spaceAround};
          `}
        >
          <styleds.signUpButton
            href='https://www.pionex.com/zh-TW/sign/ref/Z0d4ilgU?redirect=https%3A%2F%2Fwww.pionex.com%2Ffutures%2FBTC.PERP_USDT%2FManual'
            target={'_blank'}
            rel='noreferrer'
          >
            立即註冊派網
          </styleds.signUpButton>
          <styleds.customerService
            href='https://line.me/ti/g2/-YTTW37FlH736kHEdudcwyO4wyVZW7G-kr9OYg?utm_source=invitation&utm_medium=link_copy&utm_campaign=default'
            target={'_blank'}
            rel='noreferrer'
          >
            聯繫客服
          </styleds.customerService>
        </div>
      </styleds.Sidebar>
    </styleds.Container>
  )
})

const classes = {
  container: styled.div`
    ${fill_horizontal_cross_center};
    ${fontWeight500};
    padding: 0px 16px;
    width: 240px;
    height: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #aaaaaa;
    color: #7b7b7b;
    gap: 4px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      background-color: #f1f1f1;
    }
  `,
  googleIcon: styled.img`
    width: 32px;
  `,
}

const infoStyleds = {
  itemTitle: styled.p`
    ${fill_vertical_all_center};
    width: 120px;
    height: 32px;
    background-color: #5d7c9daa;
    border-radius: 5px;
    ${fontWeight500};
    margin: 0px;
  `,
  item: styled.p`
    ${fill_vertical_all_center};
    height: 32px;
    font-size: 16px;
    ${fontWeight500};
    margin: 4px;
  `,
  logoutButton: styled.div`
    ${fill_vertical_all_center};
    width: 260px;
    height: 32px;
    background-color: #ca4b4b;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 8px;
    &:hover {
      background-color: #da5b5b;
    }
  `,
  toolTipContent: styled.div`
    ${fill_vertical_all_center};
    height: 80px;
    width: 260px;
    background-color: #ca4b4baa;
    border-radius: 5px;

    > p {
      margin: 8px;
    }
  `,
  messageContent: styled.span`
    ${fill_horizontal_all_center};
    height: 30px;
  `,
}

const styleds = {
  Container: styled.div`
    ${fill_horizontal_all_center}
    position: fixed;
    z-index: 1300;
    font-family: 'HarmonyOS Sans', 'PingFang TC', 'Microsoft YaHei', sans-serif;
    background-color: #191d25;
    color: #ffffff;
  `,
  LeftPionexBot: styled.div`
    height: 900px;
    width: 100%;
    background: 50% 22.64%/1078px auto no-repeat url('pionex/pionex_demo.png'),
      radial-gradient(945px 223px at 945px 677px, #64739c 0, #000e32 79.69%, #000a23 100%);
  `,
  LeftSubTitle: styled.div`
    ${fill_horizontal_cross_center};
    color: #ffffff;
    font-size: 36px;
    font-weight: 400;
    height: 48px;
    padding: 4px 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  LeftTitle: styled.div`
    ${fill_horizontal_cross_center};
    color: #ffffff;
    height: 88px;
    font-size: 64px;
    line-height: 64px;
    -webkit-text-stroke: 0.3px #ffffff;
    padding: 4px 12px;
    margin-top: 10%;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  Sidebar: styled.div`
    ${fill_vertical_all_center};
    ${pureGrid};
    grid-template-rows: repeat(8, 12.5%);
    grid-template-columns: 100%;
    background-image: linear-gradient(188deg, #000a23, #0a183d);
    padding: 24px;
    font-weight: 900;
    animation: ${fadeIn} 3s;
  `,
  DemoContent: styled.div`
    ${fill_vertical_all_center};
    background-color: #cccccc;
    width: calc(100% - 500px);
    background-image: linear-gradient(90deg, #000a23, #000a23, #000a23), url('pionex/pionex_bg.png');
    background-position: left;
    background-size: cover;
    font-weight: 900;
    animation: ${fadeIn} 2s;
  `,
  LogoFuturesaiName: styled.div`
    ${fill_horizontal_all_center};
    grid-row: 1 / 2;
    gap: 16px;
    height: 72px;
    & > span {
      font-size: 42px;
    }
    & > img {
      width: 48px;
    }
  `,
  LogoName: styled.img`
    width: 272px;
    grid-row: 1 / 3;
  `,
  LogoSloganContent: styled.div`
    grid-row: 2 / 4;
    ${fill_vertical_all_center};
  `,
  TitleWhite: styled.div`
    color: #ffffff;
    font-size: 36px;
    line-height: 1.5;
    -webkit-text-stroke: 0.3px #ffffff;
  `,
  Subtitle: styled.div`
    font-size: 18px;
    line-height: 8px;
  `,
  LoginTitle: styled.div`
    color: #e3e3e3;
    font-size: 24px;
  `,
  LogintContent: styled.div`
    ${fill_vertical_all_center};
    height: 240px;
    gap: 16px;
    grid-row: 4 / 7;
  `,
  Copyright: styled.div`
    ${fill_horizontal_all_center}
    grid-row: 8;
    align-items: end;
  `,
  PromoCodeContent: styled.div`
    grid-row: 7;
    ${fill_vertical_all_center};
    height: 108px;
  `,
  PromoTitle: styled.div`
    ${fill_vertical_all_center};
    height: 30px;
  `,
  PromoInput: styled.div`
    ${fill_horizontal_all_center};
    height: calc(100% - 60px);
  `,
  PromoState: styled.div`
    ${fill_horizontal_all_center};
    height: 30px;
    width: 260px;
    background-color: #ca4b4b33;
    color: #fafafa;
    border-radius: 5px;
    font-size: 14px;
  `,
  signUpButton: styled.a`
    ${fill_horizontal_all_center};
    width: 240px;
    height: 48px;
    background-color: #ff7028;
    color: #fafafa;
    border-radius: 24px;
    font-size: 16px;
    &:hover {
      background-color: #fe8338;
    }
  `,
  customerService: styled.a`
    color: #fafafa;

    &:hover {
      color: #fe8338;
    }
  `,
}
